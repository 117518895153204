import DescriptionPart from "../components/descriptionPart";
import PresaleCard from "../components/presaleCard";

import "./pages.css";

const PresalePart = () => {
  return (
    <div className="w-full display-flex justify-content-center align-items-center">
      <div className="px-4 flex flex-col lg:flex-row gap-[100px] lg:gap-[50px] xl:gap-[206px] items-center justify-center">
        <DescriptionPart></DescriptionPart>
        {/* <PresaleCard></PresaleCard> */}

        <div className="card-container">
          <div className="presale-card w-[300px] h-[424px] md:w-[400px] md:h-[565px] rounded-3xl p-6 border-2 border-solid border-[#FFC000] flex flex-col gap-5">            
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresalePart;
