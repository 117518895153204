const Mission = () => {
  return (
    <div
      id="mission"
      className="px-[16px] md:px-[32px] md:gap-12 w-full flex flex-col gap-12"
    >
      <div className="max-w-[1000px] mx-auto flex flex-col gap-4">
        <div className="mx-auto flex flex-col gap-5 items-center">
          <div className="text-[32px] md:text-[52px] leading-[48px] md:leading-[64px] tracking-tighter kufam-semibold header-color">
            <span className="textShine">$BROS</span> Mission Statement
          </div>
        </div>
        <p className="font-semibold text-white text-[24px]">
          "Bringing the World Closer, One Heart at a Time" 
        </p>
        <p className="font-semibold text-white text-[20px]">
          At <span className="textShine">$BROS</span>, our mission is to revolutionize dating by eliminating borders and enhancing connections. 
          We aim to provide a dynamic, fun, and secure platform that empowers individuals to discover and appreciate the beauty of international relationships. 
          By integrating cutting-edge technology with a deep understanding of diverse cultural nuances, <span className="textShine">$BROS</span> creates meaningful interactions that go beyond traditional dating.
        </p>
        <br/>
        <p className="font-semibold text-white text-[20px]">
          Empowerment : We equip our community with the tools and knowledge to safely and effectively navigate the complexities of international dating.<br/>
          Adventure : We turn the pursuit of love into an adventure, encouraging our users to explore new places, cultures, and ideas.<br/>
          Connectivity : Through our platform, we foster a network of open-minded individuals eager to expand their social circles and embrace new experiences.<br/>
          Respect : We advocate for respect and appreciation of cultural differences, promoting a community ethos that values understanding and inclusivity.<br/>
          Fun : At the heart of <span className="textShine">$BROS</span> is the belief that dating should be exhilarating, enjoyable, and a bit adventurous.
        </p>
      </div>
    </div>
  );
};

export default Mission;
