const Vision = () => {
  return (
    <div
      id="vision"
      className="px-[16px] md:px-[32px] md:gap-12 w-full flex flex-col gap-12"
    >
      <div className="max-w-[1000px] mx-auto flex flex-col gap-4">
        <div className="mx-auto flex flex-col gap-5 items-center">
          <div className="text-[32px] md:text-[52px] leading-[48px] md:leading-[64px] tracking-tighter kufam-semibold header-color">
            <span className="textShine">$BROS</span> Project Vision
          </div>
        </div>
        <p className="font-semibold text-white text-[24px]">
          "Explore Hearts, Conquer Horizons"
        </p>
        <p className="font-semibold text-white text-[20px]">
          At <span className="textShine">$BROS</span>, we envision a world where geography doesn't limit love and relationships.
          Our project is the bridge that connects diverse hearts across the globe, turning every swipe and chat into a potential journey.
          We see a future where everyone has the freedom to explore, connect, and find companionship with fascinating people from different cultures and backgrounds, regardless of where they are on the map.
          <span className="textShine">$BROS</span> isn't just about finding dates—it's about expanding horizons, experiencing new cultures, and embarking on adventures that enrich your life.
        </p>
      </div>
    </div>
  );
};

export default Vision;
