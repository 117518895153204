export const TokenIcon = () => (
  <svg aria-hidden="true" className="w-[50px] h-[50px]" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M0 405.3V448c0 35.3 86 64 192 64s192-28.7 192-64v-42.7C342.7 434.4 267.2 448 192 448S41.3 434.4 0 405.3zM320 128c106 0 192-28.7 192-64S426 0 320 0 128 28.7 128 64s86 64 192 64zM0 300.4V352c0 35.3 86 64 192 64s192-28.7 192-64v-51.6c-41.3 34-116.9 51.6-192 51.6S41.3 334.4 0 300.4zm416 11c57.3-11.1 96-31.7 96-55.4v-42.7c-23.2 16.4-57.3 27.6-96 34.5v63.6zM192 160C86 160 0 195.8 0 240s86 80 192 80 192-35.8 192-80-86-80-192-80zm219.3 56.3c60-10.8 100.7-32 100.7-56.3v-42.7c-35.5 25.1-96.5 38.6-160.7 41.8 29.5 14.3 51.2 33.5 60 57.2z"
    ></path>
  </svg>
);

export const Curve = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
    <path className="h-[163px]" d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"></path>
  </svg>
);
export const TwitterIcon = () => (
  <svg viewBox="0 0 512 512" width="45px" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
    ></path>
  </svg>
);
export const TelegramIcon = () => (
  <svg viewBox="0 0 496 512" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z"
    ></path>
  </svg>
);

export const LinkIcon = () => (
  <svg aria-hidden="true" className="h-4 w-4" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
    ></path>
  </svg>
);
export const LinkIcon2 = () => (
  <svg aria-hidden="true" className="h-4 w-4" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M448 80v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48zm-88 16H248.029c-21.313 0-32.08 25.861-16.971 40.971l31.984 31.987L67.515 364.485c-4.686 4.686-4.686 12.284 0 16.971l31.029 31.029c4.687 4.686 12.285 4.686 16.971 0l195.526-195.526 31.988 31.991C358.058 263.977 384 253.425 384 231.979V120c0-13.255-10.745-24-24-24z"
    ></path>
  </svg>
);

export const CommunityIcon = () => (
  <svg aria-hidden="true" className="h-4 w-4" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M444.52 3.52L28.74 195.42c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 51.17 70.36 67.17 92.75 19.19l191.9-415.78c15.99-38.39-25.59-79.97-63.97-63.97z"
    ></path>
  </svg>
);

export const SettingIcon = () => (
  <svg width="16" height="14" viewBox="0 0 12 10" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.67559 1.39922C4.18425 1.39922 3.78594 1.79753 3.78594 2.28887C3.78594 2.78022 4.18425 3.17853 4.67559 3.17853C5.16694 3.17853 5.56525 2.78022 5.56525 2.28887C5.56525 1.79753 5.16694 1.39922 4.67559 1.39922ZM1.19961 2.88867H2.67329C2.93107 3.75043 3.72998 4.37853 4.67559 4.37853C5.62121 4.37853 6.42012 3.75043 6.67789 2.88867H10.7996C11.131 2.88867 11.3996 2.62004 11.3996 2.28867C11.3996 1.9573 11.131 1.68867 10.7996 1.68867H6.67777C6.41987 0.827121 5.62106 0.199219 4.67559 0.199219C3.73013 0.199219 2.93132 0.827121 2.67342 1.68867H1.19961C0.868239 1.68867 0.599609 1.9573 0.599609 2.28867C0.599609 2.62004 0.868239 2.88867 1.19961 2.88867ZM0.599609 7.58555C0.599609 7.25418 0.868239 6.98555 1.19961 6.98555H5.98367C6.24157 6.124 7.04038 5.49609 7.98585 5.49609C8.93131 5.49609 9.73012 6.124 9.98802 6.98555H10.7995C11.1309 6.98555 11.3995 7.25418 11.3995 7.58555C11.3995 7.91692 11.1309 8.18555 10.7995 8.18555H9.98814C9.73037 9.04731 8.93146 9.6754 7.98585 9.6754C7.04023 9.6754 6.24132 9.04731 5.98355 8.18555H1.19961C0.868239 8.18555 0.599609 7.91692 0.599609 7.58555ZM7.09619 7.58575C7.09619 7.09441 7.4945 6.69609 7.98585 6.69609C8.47719 6.69609 8.8755 7.09441 8.8755 7.58575C8.8755 8.07709 8.47719 8.4754 7.98585 8.4754C7.4945 8.4754 7.09619 8.07709 7.09619 7.58575Z"
      fillOpacity="0.5"
    ></path>
  </svg>
);

export const ChevronIcon = () => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 0.292893C0.683416 -0.097631 1.31658 -0.097631 1.7071 0.292893L4.99999 3.58579L8.29288 0.292893C8.6834 -0.0976311 9.31657 -0.0976311 9.70709 0.292893C10.0976 0.683417 10.0976 1.31658 9.70709 1.70711L5.7071 5.70711C5.31657 6.09763 4.68341 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683417 0.292893 0.292893Z"
      fill="currentColor"
    ></path>
  </svg>
);
export const ReloadIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_841_4053)">
      <path
        d="M11.6466 4.23513V0.706082L10.4111 1.94156C9.3173 0.741165 7.72912 0 6 0C2.6827 0 0 2.6827 0 6C0 9.3173 2.68203 12 6 12C7.69405 12 9.21142 11.2939 10.3059 10.165L9.31797 9.14128C8.50601 10.0234 7.30561 10.5879 6 10.5879C3.45892 10.5879 1.41216 8.5411 1.41216 6.00002C1.41216 3.45894 3.45892 1.41218 6 1.41218C7.34135 1.41218 8.57615 2.01238 9.42317 2.92954L8.11757 4.23515L11.6466 4.23513Z"
        fill="currentColor"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_841_4053">
        <rect width="12" height="12" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
);
