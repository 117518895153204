import { CircularProgress } from "@mui/material";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import imgTokenomics from "../assets/icon/ic-coins.png";

const Tokenomics = () => {
  return (
    <div id="tokenomics" className="px-4 md:px-12 w-full flex flex-col gap-12">
      <div className="max-w-full mx-auto flex flex-col gap-4">
        <div className="mx-auto flex flex-col gap-5 items-center">
          <div className="kufam-semibold header-color flex items-center text-[32px] md:text-[52px] leading-[48px] md:leading-[64px] tracking-tighter gap-5">
            <img
              src={imgTokenomics}
              alt="coins"
              className="w-[100px] md:w-[160px]"
            />
            Tokenomics
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-8 mt-12 mx-auto">
          <div className="w-[360px] md:w-[600px] lg:w-[800px] bg-[#ffffff0a] flex flex-col gap-4 text-left border-2 border-solid border-blue-900 rounded-3xl p-6">
            <div className="text-[32px] textShine mx-auto kufam-semibold">
              General Info
            </div>
            <div className="text-xl tracking-tight  border border-[#FFC000] rounded-[20px] px-4 py-2 flex items-center justify-between">
              <div>Name:</div>
              <span className="text-sky-400">Passport Bros</span>
            </div>
            <div className="text-xl tracking-tight  border border-[#FFC000] rounded-[20px] px-4 py-2 flex items-center justify-between">
              <div>Token Symbol:</div>
              <span className="text-sky-400">$BROS</span>
            </div>
            <div className="text-xl tracking-tight  border border-[#FFC000] rounded-[20px] px-4 py-2 flex items-center justify-between">
              <div>Total Supply:</div>
              <span className="text-sky-400">1,000,000,000</span>
            </div>
            <div className="text-xl tracking-tight  border border-[#FFC000] rounded-[20px] px-4 py-2 flex items-center justify-between">
              <div>Liquidity:</div>
              <span className="text-sky-400">20%</span>
            </div>
            {/* <div className="text-xl tracking-tight  border border-[#FFC000] rounded-[20px] px-4 py-2 flex items-center justify-between">
              <div>Token Address:</div>
              <div className="flex items-center">
                <input
                  type="button"
                  readonly=""
                  title="copy address"
                  className="cursor-pointer text-sky-400 w-[100px] md:w-full"
                  value="9CGKVEUMTD1SgX6PddnTXB6... "
                ></input>
                <button className="flex-none text-lg sm:text-[21px]">📑</button>
              </div>
            </div> */}
          </div>

          {/* <div className="max-w-[450px] flex flex-col gap-4 text-left border-2 border-solid border-[#FFC000] rounded-3xl p-6">
            <div className="text-[30px] mx-auto kufam">Voting Rights</div>
            <div className="text-xl tracking-tight  border-2 border-[#FFC000] rounded-[20px] px-4 py-2 flex items-center justify-between">
              Founders Tokens:{" "}
              <span className="text-sky-400">5 Votes each</span>
            </div>
            <div className="text-xl tracking-tight  border-2 border-[#FFC000] rounded-[20px] px-4 py-2 flex items-center justify-between">
              Elders Tokens: <span className="text-sky-400">2 Votes each</span>
            </div>
            <div className="text-xl tracking-tight  border-2 border-[#FFC000] rounded-[20px] px-4 py-2 flex items-center justify-between">
              Members At Large Tokens:{" "}
              <span className="text-sky-400">2 Votes each</span>
            </div>
            <div className="text-xl tracking-tight  border-2 border-[#FFC000] rounded-[20px] px-4 py-2 flex items-center justify-between">
              Associate, Affiliate, Charter, and Universal Members Tokens:{" "}
              <span className="text-sky-400">No Voting Rights</span>
            </div>
          </div> */}
        </div>
      </div>

      
    </div>
  );
};

export default Tokenomics;
