const Footer = () => {
  return (
    <div className="px-px py-8 overflow-hidden rounded-t-3xl bg-gradient-to-b from-white/10 to-white/0">
      <div className="h-full flex flex-row items-center justify-center text-sm text-white/75">
        © 2024 Passport Bros. All rights reserved.
      </div>
      {/* <div className="flex justify-between px-8 py-2">
        <div className="h-full bg-white/5 flex flex-row items-center justify-center text-sm text-white/75">
          © 2024 Piety Token. All rights reserved.
        </div>
        <div className="h-full bg-white/5 flex flex-row items-center justify-center text-sm text-white/75">
          © 2024 Piety Token. All rights reserved.
        </div>
      </div>
      <div className="flex justify-between px-8">
        <div className="h-full bg-white/5 flex flex-row items-center justify-center text-sm text-white/75">
          © 2024 Piety Token. All rights reserved.
        </div>
        <div className="h-full bg-white/5 flex flex-row items-center justify-center text-sm text-white/75">
          © 2024 Piety Token. All rights reserved.
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
