import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  padding: "20px 10px",
  backgroundColor: "rgba(255, 255, 255, 0.0)",
  color: "white",
  borderBottom: "0.5px solid rgba(255, 255, 255, .3)",
  "&:not(:last-child)": {},
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "white" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(4),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  textAlign: "start",
  padding: theme.spacing(5),
  borderTop: "0.5px solid rgba(255, 255, 255, .2)",
}));

const FAQ = () => {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div
      id="faq"
      className="px-[16px] md:px-[48px] w-full flex flex-col py-[100px] md:py-[160px]"
    >
      <div className="text-[32px] md:text-[52px] leading-[48px] md:leading-[64px] tracking-tighter kufam-semibold header-color mb-8">
        FAQ
      </div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>What is Piety Token?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Piety Token is a visionary cryptocurrency that combines digital
            innovation with tangible asset backing. It offers investors a secure
            and transparent investment platform anchored in community growth and
            real-world assets.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>What makes Piety Token unique?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Piety Token stands out by being backed by tangible assets such as
            gold and a share of the company. It merges the digital and physical
            worlds, providing a stable investment option with potential for
            growth and community engagement.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>How can I participate in the Piety Token ICO?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To participate in the ICO, you need to register on our platform,
            complete the KYC verification process, and then you can purchase
            tokens using MATIC or other supported payment methods.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>
            How does Piety Token ensure security and compliance?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ol>
              Piety Token implements AML (Anti-Money Laundering) and KYC (Know
              Your Customer) procedures to ensure regulatory compliance and
              prevent illicit activities. Additionally, we protect our
              intellectual property and ensure a secure investment environment.
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>
            What are the benefits of investing in Piety Token?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ol>
              Investing in Piety Token offers stability through asset backing,
              potential for growth through strategic reinvestments, and
              community engagement through various membership levels and voting
              rights.
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography>Can I earn dividends by holding Piety Tokens?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ol>
              Yes, all token holders are eligible to receive monthly cash
              dividends based on a 5% members tax on monthly revenues.
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FAQ;
