import React from "react";

import Navbar from "./layouts/navbar";
import PresalcPart from "./pages/PresalePart";

import "./App.css";
import Tokenomics from "./pages/Tokenomics";
import Footer from "./pages/Footer";
import FAQ from "./pages/FAQ";
import Vision from "./pages/Vision";
import Mission from "./pages/Mission";

import { TelegramIcon, TwitterIcon } from "./assets/constants";

function App() {
  return (
    <div className="App bg-linear-gradient">
        <Navbar></Navbar>
        <div
          id="home"
          className="px-[16px] md:px-10 pt-6 md:pt-[100px] flex flex-col gap-[100px] md:gap-[160px]"
        >
          <PresalcPart></PresalcPart>
          <Vision />
          <Mission />
        </div>

        {/* <Pricing /> */}

        <div className="pt-[160px] flex flex-col gap-[100px] md:gap-[160px]">
          <Tokenomics />
        </div>

        <section className="scroll-mt-12" id="social">
          <div className="max-w-6xl mx-auto py-16 md:py-24 px-4 lg:px-0">
            {/* <div className="mb-8 flex items-center justify-center">
              <img
                src="/imgs/wife-social.png"
                alt="home-the-wife"
                className="max-w-[120px] md:max-w-[140px] lg:max-w-[160px] overflow-hidden border-[5px] border-[#f7ef2a]"
                style={{borderRadius:'20px'}}
              />
            </div> */}
            <h3 className="kufam-semibold header-color text-center text-[32px] md:text-[52px] leading-[48px] md:leading-[64px] bg-aboutgrad block text-transparent bg-clip-text">
              Follow <span className="textShine">$BROS</span> on social media
            </h3>
            <div className="flex items-center justify-center space-x-9 pt-6">
              <a
                href="https://x.com/passportbrosol?s=21&t=U9FHYkKLcG_alau3Wxqjbg"
                target="_blank"
                rel="noreferrer"
                className="bg-black text-white md:h-[80px] md:w-[80px] h-[70px] w-[70px]  rounded-full border-4 border-[#ffff] flex items-center justify-center"
              >
                <TwitterIcon />
              </a>
              <a
                href="https://t.me/+G1EjNlk9QmphNzQx"
                target="_blank"
                rel="noreferrer"
                className="bg-[#2ca5e0] text-white md:h-[80px] md:w-[80px] w-[70px]  h-[70px] rounded-full border-4 border-[#2ca5e0] flex items-center justify-center"
              >
                <TelegramIcon />
              </a>
            </div>
          </div>
        </section>
        
        {/*<FAQ />*/}
        <Footer />
    </div>
  );
}

export default App;
