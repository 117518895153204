import "./layouts.css";
import PreButton from "../components/preButton";
import logo from "../assets/img/logo.svg";

const Navbar = () => {
  return (
    <div className="mx-[24px] md:mx-[40px] lg:mx-auto lg:px-[40px] h-[80px] lg:max-w-[1200px] flex flex-row gap-2 items-center justify-between z-50">
      <div className="w-[250px] h-10 flex flex-row items-center gap-2.5">
        <img src={logo} className="img-logo-header w-[80%] relative z-10 overflow-hidden border-[2px] border-[#805ee8]" style={{borderRadius:'20%'}} alt="BROS" />
        <p className="text-[24px] kufam-semibold mt-2">
          Passport <span className="text-[#00a5ff]">Bros</span>
        </p>
      </div>
      <div className="hidden md:block  w-[370px] h-[40px] p-px overflow-hidden bg-gradient-to-b to-[#68F2C91C] rounded-3xl flex ">
        <div className="w-full h-full bg-[#c9d1d936] flex flex-row gap-[24px] items-center justify-center">
          <button
            className="text-md font-medium"
            onClick={(e) => {
              let home = document.getElementById("home");
              e.preventDefault();
              home &&
                home.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              window.history.pushState("home", "home", "/");
            }}
          >
            Home
          </button>
          {/* <button
            className="text-md font-medium"
            onClick={(e) => {
              let product = document.getElementById("product");
              e.preventDefault();
              product &&
                product.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              window.history.pushState("product", "product", "/");
            }}
          >
            Vision
          </button>
          <button
            className="text-md font-medium"
            onClick={(e) => {
              let pricing = document.getElementById("pricing");
              e.preventDefault();
              pricing &&
                pricing.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              window.history.pushState("pricing", "pricing", "/");
            }}
          >
            Mission
          </button> */}
          <button
            className="text-md font-medium"
            onClick={(e) => {
              let team = document.getElementById("tokenomics");
              e.preventDefault();
              team &&
                team.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              window.history.pushState("tokenomics", "tokenomics", "/");
            }}
          >
            Tokenomics
          </button>
          <button
            className="text-md font-medium"
            onClick={(e) => {
              let faq = document.getElementById("social");
              e.preventDefault();
              faq &&
                faq.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              window.history.pushState("social", "social", "/");
            }}
          >
            Social
          </button>
        </div>
      </div>
      <a 
        href="https://t.me/+G1EjNlk9QmphNzQx"
        target="_blank"
        rel="noreferrer"
        >
        <button className="w-[120px] h-9 flex flex-row items-center justify-center rounded-3xl px-4 py-2 text-[14px] bg-[#805ee8]">
          JOIN US
        </button>
      </a>
    </div>
  );
};

export default Navbar;
