import "./components.scss";

const DescriptionPart = () => {
  return (
    <div className="w-full lg:w-[500px] min-h-[404px] flex flex-col gap-6 items-center">
      <div className="flex flex-col gap-4 relative">
        <div className="text-[42px] md:text-[56px] leading-none text-left kufam-semibold header-color">
          <div className="textShine md:text-[72px]"> 
            Passport Bros
          </div>
          <div className="relative mt-6">
            <span className="relative">
              <span>Welcome </span>
              {/* <span className="reflection color-brown  top-[27px] md:top-[48px]">
                Welcome{" "}
              </span> */}
            </span>
            <span className="relative">
              <span className="textShine font-bold">$BROS</span>
              <span className="textShine font-bold reflection color-gold top-[27px] md:top-[48px]">
                $BROS
              </span>
            </span>
          </div>
        </div>
        <p className="text-white/60 text-left md:mt-6 text-[18px]">
          <span className="font-semibold text-white">
            Ever fantasized about dating around the globe?{" "}
          </span>{" "}
            Say hello to Passport Bros, where we're all about loving without borders. 
          <br></br>
            <span className="textShine">$BROS</span> isn’t just another token—it’s your golden ticket to a worldwide dating adventure.
          <br></br>
          <span className="font-semibold text-white">
            We’re the crew that cheers for the thrill of the chase across continents{" "}
          </span>{" "}
            Forget the local scene; with <span className="textShine">$BROS</span>, you’re scoring dates that come with new stamps on your passport.  
          <br></br>
            It’s about mixing up the dating game with accents that dazzle, cultures that intrigue, and a splash of exotic charm.
          <br></br>
          <span className="font-semibold text-white">
            Join the club where dating’s a jet-setting journey.{" "}
          </span>{" "}
            With <span className="textShine">$BROS</span>, you’re not just in for a crypto ride—you’re signing up for epic tales of romance and connections that defy the usual.
          <br></br>
          <br></br>
          <span className="font-semibold text-white text-[24px]">
            Epic Giveaway Alert! Ready to jet?
          </span>
        </p>
        {/* <div className="flex flex-col gap-2.5 text-[18px]">
          <div className="flex flex-row gap-2 items-start">
            <img src="/assets/img/check.svg" alt="check" />
            <p className="text-white/60 text-left">
              <span className="font-semibold text-white">
                Community Governance:
              </span>{" "}
              Participate in platform development and vote on feature proposals.
            </p>
          </div>
        </div> */}
      </div>
      {/* <button className="w-[166px] h-[36px] rounded-3xl px-4 py-2 border border-solid border-[#3555FF] text-sm font-medium tracking-tight flex flex-row gap-1 items-center">
        <img src="/assets/img/document.svg" alt="" />
        Read WhitePaper
      </button> */}
      <button className="w-[50%] h-9 mt-4 flex flex-row items-center justify-center rounded-3xl px-4 py-2 text-[16px] font-bold bg-[#805ee8]">
        Buy Now
      </button>
    </div>
  );
};

export default DescriptionPart;
